<template>
  <div>
    <v-layout
      wrap
      class="pr-12 pr-sm-4 pl-sm-4 pl-10 mt-2 mb-14 d-flex justify-center"
    >
      <v-flex>
        <router-view></router-view>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      permission: this.$store.state.Authentication.perfil,
    };
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
