import { render, staticRenderFns } from "./Personas.vue?vue&type=template&id=7058cdba&scoped=true"
import script from "./Personas.vue?vue&type=script&lang=js"
export * from "./Personas.vue?vue&type=script&lang=js"
import style0 from "./Personas.vue?vue&type=style&index=0&id=7058cdba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7058cdba",
  null
  
)

export default component.exports